//import  CustomIcon from 'assets/images/logo-icon.svg'
import { ReactComponent as dashboard } from 'assets/images/sidemenu/dashboard.svg';
import { ReactComponent as user } from 'assets/images/sidemenu/user.svg';
import { ReactComponent as payi } from 'assets/images/sidemenu/empty-wallet.svg';
import { ReactComponent as seti } from 'assets/images/sidemenu/setting.svg';
import { ReactComponent as reporti } from 'assets/images/sidemenu/reports.svg';
import { ReactComponent as map } from 'assets/images/sidemenu/map.svg';
import { ReactComponent as back } from 'assets/images/sidemenu/logout2.svg';

const icons = {
  dashboard: dashboard,
  user: user,
  payi: payi,
  seti: seti,
  reporti: reporti,
  map: map,
  back: back
};

const storedUserDetails = localStorage.getItem('rolepermission');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const company = {
  id: 'navigation',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/company/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'userprofile',
      title: 'User Profile',
      type: 'item',
      url: '/company/user-profile',
      icon: icons.user,
    },
    {
      id: 'documentmanager',
      title: 'Document Manager',
      type: 'item',
      url: '/company/documentlist',
      icon: icons.user,   
    },
    {
      id: 'view_location',
      title: 'View Location',
      type: 'item',
      url: '/company/location-view',
      icon: icons.map,     
    },
    {
      id: 'location-data',
      title: 'Location Data',
      type: 'item',
      url: '/company/location-data',
      icon: icons.payi,
    },
    {
      id: 'locationorders',
      title: 'Location Orders',
      type: 'item',
      url: '/company/location-orders',
      icon: icons.payi,
    },
    {
      id: 'other_location',
      title: 'Other Request',
      type: 'collapse',
      icon: icons.map,
      children: [
        {
          id: 'lcdw_service',
          title: 'LCDW Services',
          type: 'item',
          url: '/company/other-location/lcdw-service',
        },
        {
          id: 'utilities_plan',
          title: 'Utilities Plan',
          type: 'item',
          url: '/company/other-location/utilities-plan',
        },
        {
          id: 'authorities_approval',
          title: 'Authorities Approval',
          type: 'item',
          url: '/company/other-location/authorities-approval',
        },
        {
          id: 'survey',
          title: 'Survey',
          type: 'item',
          url: '/company/other-location/survey',
        },
        {
          id: 'gpr_surveys',
          title: 'GPR Surveys',
          type: 'item',
          url: '/company/other-location/gpr-surveys',
        },
      ]
    },
    {
      id: 'request-list',
      title: 'Request List',
      type: 'collapse',
      icon: icons.requesti,
      children: [
        {
          id: 'lcdw',
          title: 'LCDW Services',
          type: 'item',
          url: '/company/request/lcdw-services-request'
        },
        {
          id: 'utilities',
          title: 'Utilities Plan Request',
          type: 'item',
          url: '/company/request/utilities-plan-request'
        },
        {
          id: 'approval',
          title: 'Authorities Approval',
          type: 'item',
          url: '/company/request/authorities-approval-request'
        },
        {
          id: 'survey',
          title: 'Survey',
          type: 'item',
          url: '/company/request/survey-request'
        },
        {
          id: 'gprsurveys',
          title: 'GPR Surveys',
          type: 'item',
          url: '/company/request/gpr-surveys-request'
        }
      ]
    },
    {
      id: 'user_management',
      title: 'User Management',
      type: 'collapse',
      breadcrumbs: true,
      icon: icons.user,
      children: [
        {
          id: 'View-users',
          title: 'View User',
          type: 'item',
          url: '/company/user-management/view-user',
        },
        // {
        //   id: 'View-user-activity',
        //   title: 'View User Activity',
        //   type: 'item',
        //   url: '/company/view-user-activity'
        // }
      ]
    },
    {
      id: 'role_permission',
      title: 'Roles',
      type: 'collapse',
      icon: icons.user,
      breadcrumbs: true,
      children: [
        {
          id: 'list-of-roles',
          title: 'List of Roles',
          type: 'item',
          url: '/company/roles/list-of-roles',
        },
        {
          id: 'permission',
          title: 'Permission',
          type: 'item',
          url: '/company/roles/permission',
        }
      ]
    },
    {
      id: 'payments',
      title: 'Payments',
      type: 'item',
      url: '/company/payments',
      icon: icons.payi,
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/company/settings',
      icon: icons.seti,
    },
    {
      id: 'reports',
      title: 'Reports',
      type: 'collapse',
      icon: icons.reporti,
      children: [
        {
          id: 'payment-reports',
          title: 'Payment Reports',
          type: 'item',
          url: '/company/reports/payment-reports',
        },
        {
          id: 'location-reports',
          title: 'Location Reports',
          type: 'item',
          url: '/company/reports/location-reports',
        }
      ]
    },
    {
      id: 'useractivity',
      title: 'Activity',
      type: 'item',
      url: '/company/user-activity',
      icon: icons.user,
    },
    {
      id: 'loginlogs',
      title: 'Login Logs',
      type: 'item',
      url: '/company/login-logs',
      icon: icons.user,

    },
    // {
    //   id: 'Demopage',
    //   title: 'Demopage',
    //   type: 'item',
    //   icon: icons.back,
    //   url: 'company/demopage'
    // },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      icon: icons.back,
      url: '/logout'
    }

  ]

};

const modulesToCheck = ['documentmanager', 'view_location', 'locations', 'user_management', 'role_permission', 'payments', 'settings', 'reports', 'useractivity', 'loginlogs'];

if (company && Array.isArray(company.children) && loginpermission && Array.isArray(loginpermission)) {

  modulesToCheck.forEach(moduleId => {
    const module = company.children.find(child => child.id === moduleId);

    if (module) {
      const modulePermission = loginpermission.find(permission => permission.module === moduleId);
      if (modulePermission) {
        if (modulePermission.create === 0 &&
          modulePermission.read === 0 &&
          modulePermission.update === 0 &&
          modulePermission.deletePermission === 0) {
          company.children = company.children.filter(child => child.id !== moduleId);
        }
      }
    }
  });
} else {
  console.error('support.children or loginpermission is undefined or not an array');
}

if (company.children.length === 0) {
  company.title = '';
}


export default company;