import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Grid, Typography } from '@mui/material';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import MainCard from 'components/MainCard';
import { ArrowRight2 } from 'iconsax-react';
import { useBreadcrumb } from './BreadcrumbContext';

const Breadcrumbs = ({
  card = true,
  divider = true,
  maxItems = 8,
  navigation,
  rightAlign = false,
  separator = ArrowRight2,
  title = false,
  titleBottom = false,
  sx,
  ...others
}) => {
  const location = useLocation();
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const { setBreadcrumbTitle } = useBreadcrumb();

  useEffect(() => {
    if (breadcrumbItems.length > 0) {
      // Set the title of the last breadcrumb item
      setBreadcrumbTitle(breadcrumbItems[breadcrumbItems.length - 1]?.title || '');
    }
  }, [breadcrumbItems, setBreadcrumbTitle]);

  // Recursive function to find breadcrumb items
  const findBreadcrumb = (menu, currentPath, accumulatedPath = '') => {
    let items = [];
    menu.forEach((item) => {
      if (item.Breadcrumbs === false || item.hidden) return;

      const itemPath = `${accumulatedPath.replace(/\/$/, '')}/${item.path.replace(/^\//, '')}`;
      const pathRegex = new RegExp(`^${itemPath.replace(/:(\w+)/g, '([^/]+)')}(|$)`);

      if (pathRegex.test(currentPath)) {
        items.push({
          ...item,
          url: itemPath,
          title: item.title
        });

        // Check for children only if they exist
        if (item.children && item.children.length > 0) {
          const childItems = findBreadcrumb(item.children, currentPath, itemPath);
          items = [...items, ...childItems];
        }
      }
    });
    return items;
  };

  useEffect(() => {
    if (!navigation || !navigation.children) return;

    const path = location.pathname;
    const items = findBreadcrumb(navigation.children, path);
    setBreadcrumbItems(items);
  }, [location, navigation]);

  const SeparatorIcon = separator || ArrowRight2;
  const separatorIcon = <SeparatorIcon size={12} />;

  return (
    <MainCard
      border={card}
      sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
      {...others}
      content={card}
      boxShadow={false}
    >
      <Grid
        container
        direction={rightAlign ? 'row' : 'column'}
        justifyContent={rightAlign ? 'space-between' : 'flex-start'}
        alignItems={rightAlign ? 'center' : 'flex-start'}
        spacing={0.5}
      >
        {/* Top Title */}
        {title && !titleBottom && (
          <Grid item>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {breadcrumbItems[breadcrumbItems.length - 1]?.title || ''}
            </Typography>
          </Grid>
        )}
        {/* Breadcrumb Items */}
        <Grid item>
          <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems} separator={separatorIcon}>
            {breadcrumbItems.map((item, index) => (
              <Typography
                key={index}
                // component={Link}
                to={item.url}
                variant="h6"
                sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                color={index === breadcrumbItems.length - 1 ? 'textPrimary' : 'secondary'}
              >
                {item.title}
              </Typography>
            ))}
          </MuiBreadcrumbs>
        </Grid>
        {/* Bottom Title */}
        {title && titleBottom && (
          <Grid item sx={{ mt: card === false ? 0 : 1 }}>
            <Typography variant="h2" sx={{ fontWeight: 700 }}>
              {breadcrumbItems[breadcrumbItems.length - 1]?.title || ''}
            </Typography>
          </Grid>
        )}
      </Grid>
      {/* Divider */}
      {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
    </MainCard>
  );
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Breadcrumbs;
