import { ReactComponent as box } from 'assets/images/sidemenu/box.svg';
import { ReactComponent as map } from 'assets/images/sidemenu/map.svg';
import { ReactComponent as seti } from 'assets/images/sidemenu/task-square.svg';


const icons = {
  box: box,
  map: map,
  seti: seti
};

const storedUserDetails = localStorage.getItem('rolepermission');

const logUserType = localStorage.getItem('usertype');

let userpermission = null;
let loginpermission = [];

if (storedUserDetails) {
  try {
    userpermission = JSON.parse(storedUserDetails);
    loginpermission = userpermission.permissions || [];
  } catch (error) {
    console.error('Failed to parse storedUserDetails:', error);
  }
}

const location = {
  id: 'location',
  title: 'Location',
  type: 'group',
  children: [
    // {
    //   id: 'Category',
    //   title: 'Category',
    //   type: 'collapse',
    //   icon: icons.box,
    //   children: [
    //     {
    //       id: 'view_cate',
    //       title: 'View Category',
    //       type: 'item',
    //       url: '/admin/category/view',
    //     },
    //     // {
    //     //   id: 'add-user',
    //     //   title: 'Add Category',
    //     //   type: 'item',
    //     //   url: '/admin/category/add',
    //     // }
    //   ]
    // },
    {
      id: 'Category',
      title: 'Category',
      type: 'item',
      url: '/admin/category/view',
      icon: icons.seti,
    },
    {
      id: 'Location',
      title: 'Locations',
      type: 'collapse',
      icon: icons.map,
      children: [
        {
          id: 'locat_list',
          title: 'Location List',
          type: 'item',
          url: '/admin/location/list',
        },
        {
          id: 'add-user',
          title: 'Location Data Approval',
          type: 'item',
          url: '/admin/location/Data-approval',
        },
        // {
        //   id: 'adduser',
        //   title: 'Location ',
        //   type: 'item',
        //   url: '/admin/location/location-orders',
        // },
        {
          id: 'location-orders',
          title: 'Location Orders',
          type: 'item',
          url: '/admin/location/location-orders',
        }
        // {
        //   id: 'add-user',
        //   title: 'Add Locations',
        //   type: 'item',
        //   url: '/admin/location/add',
        // }
      ]
    }

  ]
};


const modulesToCheck = ['Category', 'Location'];

if (logUserType !== 'super_admin') {

  if (location && Array.isArray(location.children) && loginpermission && Array.isArray(loginpermission)) {

    
    modulesToCheck.forEach(moduleId => {
      const module = location.children.find(child => child.id === moduleId);

      if (module) {
        const modulePermission = loginpermission.find(permission => permission.module === moduleId);

        if (modulePermission) {
          if (modulePermission.create === 0 &&
            modulePermission.read === 0 &&
            modulePermission.update === 0 &&
            modulePermission.deletePermission === 0) {
            location.children = location.children.filter(child => child.id !== moduleId);
          }
        }
      }
    });
  } else {
    console.error('support.children or loginpermission is undefined or not an array');
  }
}

if (location.children.length === 0) {
  location.title = '';
}
export default location;