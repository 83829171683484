import { lazy } from 'react';

// project-imports
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import PermissionGuard from 'utils/route-guard/PermissionGuard';

const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/error/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/error/500')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon/coming-soon')));
const MaintenanceErrorFor = Loadable(lazy(() => import('pages/maintenance/error/404_forbidden')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/sample-page')));

const SamplePage3 = Loadable(lazy(() => import('pages/extra-pages/sample-page3')));
const ViewLayout = Loadable(lazy(() => import('pages/location/locationList')));

// setting
const AppLogo = Loadable(lazy(() => import('pages/settings/AppLogo')));
const General = Loadable(lazy(() => import('pages/settings/General')));
const Email = Loadable(lazy(() => import('pages/settings/Email')));
const Payment = Loadable(lazy(() => import('pages/settings/Payment')));
const GoogleMapapi = Loadable(lazy(() => import('pages/settings/GoogleMapapi')));
const LcdwPaymentSettings = Loadable(lazy(() => import('pages/settings/LcdwPaymentSettings')));
const EmailPreferences = Loadable(lazy(() => import('pages/settings/EmailPreferences')));
const FileFormat = Loadable(lazy(() => import('pages/settings/FileFormatSize')));
const MailTemplate = Loadable(lazy(() => import('pages/settings/EmailTemplate')));
const ViewMailTemplate = Loadable(lazy(() => import('pages/settings/ViewEmailTemplate')));
const ListEmail = Loadable(lazy(() => import('pages/settings/List Email Template')));
const EditEmail = Loadable(lazy(() => import('pages/settings/Edit Email Template')));
const Currency = Loadable(lazy(() => import('pages/settings/Currency')));
const Location = Loadable(lazy(() => import('pages/settings/LocationLimits')));
const LocationLogs = Loadable(lazy(() => import('pages/location/locationlogs')));
const Sms = Loadable(lazy(() => import('pages/settings/SMS')));
// company user
const CompanyUserView = Loadable(lazy(() => import('pages/user/companyuser/view')));
const CompanyUser = Loadable(lazy(() => import('pages/user/companyuser/user')));
const CompanySubuser = Loadable(lazy(() => import('pages/user/companyuser/subuser')));
const ListUsers = Loadable(lazy(() => import('pages/user/listusers')));

//lcdw user
const LcdwUserView = Loadable(lazy(() => import('pages/user/lcdwuser/view')));
const LcdwUserActivity = Loadable(lazy(() => import('pages/user/lcdwuser/viewlcdwuseractivity')));
const LcdwViewActivity = Loadable(lazy(() => import('pages/user/lcdwuser/listlcdwactivity')));

// admin user
//roles
const ListOfRoles = Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/listofroles')));
const AddRoles = Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/addrole')));
const EditRoles = Loadable(lazy(() => import('pages/user/adminuser/subuserroles/roles/editrole')));
const Permission = Loadable(lazy(() => import('pages/user/adminuser/subuserroles/permission')));

// sub Users
const ListOfSubUser = Loadable(lazy(() => import('pages/user/adminuser/subusermangement/listofadminsubuser')));
const ViewSubUserActivity = Loadable(lazy(() => import('pages/user/adminuser/subusermangement/viewsubuseractivity')));
const ViewActivity = Loadable(lazy(() => import('pages/user/adminuser/subusermangement/viewactivity')));

// Staff Users
const ListOfAdminStaffUsers = Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/listofadminstaffusers')));
const ViewStaffUserActivity = Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/viewstaffuseractivity')));
const ViewStaffFollowUpList = Loadable(lazy(() => import('pages/user/adminuser/staffusermangement/staff-followuplist')));

// category
const CategoryView = Loadable(lazy(() => import('pages/category/view')));
const CategoryEdit = Loadable(lazy(() => import('pages/category/edit')));
// Payment
// const Invoices=Loadable(lazy(() => import('pages/payment-statement/invoices')));

//location
const AddLocation = Loadable(lazy(() => import('pages/location/LocationAdd')));
const EditLocation = Loadable(lazy(() => import('pages/location/locationEdit')));
const ViewLocation = Loadable(lazy(() => import('pages/location/LocationView')));
const PaymentRequestlist = Loadable(lazy(() => import('pages/location/payment-request-list')));

//request invoice
const RequestInvoice = Loadable(lazy(() => import('pages/request/Paymentinvoice')));

//view request
const LcdwService = Loadable(lazy(() => import('pages/request/RequestMain')));
const UtilitiesPlan = Loadable(lazy(() => import('pages/request/UtilitiesRequest')));
const AuthoritiesApproval = Loadable(lazy(() => import('pages/request/AuthoritiesRequest')));
const Survey = Loadable(lazy(() => import('pages/request/SurveyRequest')));
const GprSurveys = Loadable(lazy(() => import('pages/request/GPRSurveys')));

const RequestView = Loadable(lazy(() => import(`pages/request/RequestView`)));
const EditLocationRequest = Loadable(lazy(() => import(`pages/request/RequestEdit`)));
const AddLocationRequest = Loadable(lazy(() => import(`pages/request/RequestAdd`)));
const ViewLocationRequest = Loadable(lazy(() => import(`pages/request/viewData`)));
// industry
const IndustryList = Loadable(lazy(() => import('pages/Industry/industryList')));
// country
const CountryList = Loadable(lazy(() => import('pages/country/countryList')));
//cms
const CreateCms = Loadable(lazy(() => import('pages/cms/create')));
const ListCms = Loadable(lazy(() => import('pages/cms/list')));
const EditCms = Loadable(lazy(() => import('pages/cms/edit')));
const ViewCms = Loadable(lazy(() => import('pages/cms/view')));
const TemplateCms = Loadable(lazy(() => import('pages/cms/template')));
// const PreviewPage= Loadable(lazy(()=> import('pages/cms/previewpage')));
const LandingPage = Loadable(lazy(() => import('components/LandingPage')));

//Feedback
const FeedbackContact = Loadable(lazy(() => import('pages/settings/feedback-form')));
const LocationFeedback = Loadable(lazy(() => import('pages/settings/location-feedback')));

const Header = Loadable(lazy(() => import('pages/settings/Header')));
// const Activity = Loadable(lazy(() => import('pages/settings/Activity')));
const Footer = Loadable(lazy(() => import('pages/settings/Footer')));

const LoginLogs = Loadable(lazy(() => import('pages/logs/logsMain')));
const PaymentLogs = Loadable(lazy(() => import('pages/logs/paymentLogs')));
const CartCheckList = Loadable(lazy(() => import('pages/checklist/cart')));

//photoapproval
const PhotoApproval = Loadable(lazy(() => import('pages/location/photoApproval/photoApprList')));
const EditLocationData = Loadable(lazy(() => import('pages/location/photoApproval/EditLocationData')));
const ViewLocationData = Loadable(lazy(() => import('pages/location/photoApproval/ViewLocationData')));

const LcdwWithdrawal = Loadable(lazy(() => import('pages/payment/LcdwWithdrawal')));

// Payment
const Invoices = Loadable(lazy(() => import('pages/location/Paymentinvoice')));
const Statements = Loadable(lazy(() => import('pages/payment/statements')));
const PaymentReports = Loadable(lazy(() => import('pages/reports/paymentreports')));
const WithdrawalReports = Loadable(lazy(() => import('pages/reports/withdrawalreports')));

const LocationReports = Loadable(lazy(() => import('pages/reports/locationReports')));
const InvoiceReports = Loadable(lazy(() => import('pages/payment/invoices')));
const LcdwPaymentStatement = Loadable(lazy(() => import('pages/payment/lcdwPaymentstatement')));
const Logout = Loadable(lazy(() => import('pages/settings/logout')));
const LCDWServices = Loadable(lazy(() => import('pages/request/lcdwserviceAdd')));

// ==============================|| MAIN ROUTES ||============================== //

// const ViewLayout = () => {
//   return (
//     <div>
//       <WidgetStatistics />
//       <PaginationTable />
//     </div>
//   );
// };

const MainRoutes = {
  id: 'navigation',
  title: 'Navigation',
  // Breadcrumbs: true,
  type: 'group',
  path: '/',
  children: [
    {
      Breadcrumbs: false,
      path: 'logout',
      element: <Logout />
    },
    {
      Breadcrumbs: false,
      path: '/landingpage',
      element: <LandingPage />
    },
    {
      Breadcrumbs: false,
      path: '/preview/:id',
      element: <ViewCms />
    },
    {
      Breadcrumbs: false,
      path: '/:title',
      element: <TemplateCms />
    },
    {
      path: '/',
      Breadcrumbs: true,
      title: 'Home',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          path: 'admin/dashboard',
          element: <SamplePage />
        },
        {
          id: 'category',
          title: 'Category',
          type: 'item',
          path: 'admin/category/view',
          // element: <CategoryView/>, // Use ViewLayout for viewing data
          element: (
            <PermissionGuard moduleId="Category">
              <CategoryView />
            </PermissionGuard>
          )
        },
        // {
        //   path: 'admin/category/add',
        //   element: <CategoryAdd/>
        // },
        {
          // id: 'category',
          // title: 'Category',
          // type: 'item',
          path: 'admin/category/edit/:id',
          // element: <CategoryEdit/>
          element: (
            <PermissionGuard moduleId="Category">
              <CategoryEdit />
            </PermissionGuard>
          )
        },
        {
          id: 'listusers',
          title: 'Users List',
          type: 'item',
          path: 'admin/list-users',
          element: <ListUsers />
        },
        {
          title: 'Locations',
          path: 'admin/location',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'list',
              title: 'Location List',
              type: 'item',
              path: 'list',
              // element: <ViewLayout />
              element: (
                <PermissionGuard moduleId="Location">
                  <ViewLayout />
                </PermissionGuard>
              )
            },
            {
              id: 'add',
              title: 'Location Add',
              type: 'item',
              path: 'add',
              // element: <AddLocation  />
              element: (
                <PermissionGuard moduleId="Location">
                  <AddLocation />
                </PermissionGuard>
              )
            },
            {
              id: 'edit',
              title: 'Location Edit',
              type: 'item',
              path: 'edit/:id',
              // element: <EditLocation  />
              element: (
                <PermissionGuard moduleId="Location">
                  <EditLocation />
                </PermissionGuard>
              )
            },
            {
              id: 'view',
              title: 'Location View',
              type: 'item',
              path: 'view/:id',
              // element: <ViewLocation  />
              element: (
                <PermissionGuard moduleId="Location">
                  <ViewLocation />
                </PermissionGuard>
              )
            },
            {
              id: 'location-orders',
              title: 'Location Orders',
              type: 'item',
              path: 'location-orders',
              // element: < PaymentRequestlist />
              element: (
                <PermissionGuard moduleId="Location">
                  <PaymentRequestlist />
                </PermissionGuard>
              )
            },
            {
              id: 'Data-approval',
              title: 'Data Approval',
              type: 'item',
              path: 'Data-approval',
              // element: <PhotoApproval />
              element: (
                <PermissionGuard moduleId="Location">
                  <PhotoApproval />
                </PermissionGuard>
              )
            },
            {
              id: 'edit-data',
              title: 'Edit Data',
              type: 'item',
              path: 'edit-data/:id',
              // element: <EditLocationData />
              element: (
                <PermissionGuard moduleId="Location">
                  <EditLocationData />
                </PermissionGuard>
              )
            },
            {
              id: 'view-data',
              title: 'View Data',
              type: 'item',
              path: 'view-data/:id',
              // element: <ViewLocationData />
              element: (
                <PermissionGuard moduleId="Location">
                  <ViewLocationData />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'ViewRequests',
          title: 'Request',
          path: 'admin/request',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'lcdw-services',
              title: 'LCDW Services',
              type: 'item',
              path: 'lcdw-services',
              // element: <ViewRequest />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <LcdwService />
                </PermissionGuard>
              )
            },
            {
              id: 'add-lcdw-service',
              title: 'Add LCDW Services',
              type: 'item',
              path: 'add-lcdw-service',
              // element: <LCDWServices />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <LCDWServices />
                </PermissionGuard>
              )
            },
            {
              id: 'utilitiesplan',
              title: 'Utilities Plan',
              type: 'item',
              path: 'utilitiesplan',
              // element: <ViewRequest />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <UtilitiesPlan />
                </PermissionGuard>
              )
            },
            {
              id: 'authorities-approval',
              title: 'Authorities Approval',
              type: 'item',
              path: 'authorities-approval',
              // element: <ViewRequest />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <AuthoritiesApproval />
                </PermissionGuard>
              )
            },
            {
              id: 'survey',
              title: 'Survey',
              type: 'item',
              path: 'survey',
              // element: <ViewRequest />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <Survey />
                </PermissionGuard>
              )
            },
            {
              id: 'gpr-surveys',
              title: 'GPR Surveys',
              type: 'item',
              path: 'gpr-surveys',
              // element: <ViewRequest />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <GprSurveys />
                </PermissionGuard>
              )
            },
            {
              id: 'view-data',
              title: 'View Data',
              type: 'item',
              path: 'view-data/:id',
              // element: <EditLocation  />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <ViewLocationRequest />
                </PermissionGuard>
              )
            },
            {
              id: 'view',
              title: 'View',
              type: 'item',
              path: 'view/:id',
              // element: <RequestView />
              element: (
                <PermissionGuard moduleId="ViewRequests">
                  <RequestView />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          path: '/admin/location/request-add/:id',
          // element: <EditLocation  />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <AddLocationRequest />
            </PermissionGuard>
          )
        },
        {
          path: '/admin/location/request-edit/:id',
          // element: <EditLocation  />
          element: (
            <PermissionGuard moduleId="ViewRequests">
              <EditLocationRequest />
            </PermissionGuard>
          )
        },
        {
          path: 'admin/request-invoice/:id',
          element: <RequestInvoice />
        },
        {
          id: 'admin/settings',
          title: 'Setting',
          type: 'group',
          path: 'admin/settings',
          Breadcrumbs: true,
          children: [
            {
              id: 'applogo',
              title: 'App Logo',
              type: 'item',
              path: 'applogo',
              // element: <AppLogo />
              element: (
                <PermissionGuard moduleId="Setting">
                  <AppLogo />
                </PermissionGuard>
              )
            },
            {
              id: 'general',
              title: 'General',
              type: 'item',
              path: 'general',
              // element: <General />
              element: (
                <PermissionGuard moduleId="Setting">
                  <General />
                </PermissionGuard>
              )
            },
            {
              id: 'email',
              title: 'Email',
              type: 'item',
              path: 'email',
              // element: <Email />
              element: (
                <PermissionGuard moduleId="Setting">
                  <Email />
                </PermissionGuard>
              )
            },
            {
              id: 'payment',
              title: 'Payment',
              type: 'item',
              path: 'payment',
              element: (
                <PermissionGuard moduleId="Setting">
                  <Payment />
                </PermissionGuard>
              )
            },
            {
              id: 'googlemapapi',
              title: 'Googlemap Api',
              type: 'item',
              path: 'googlemapapi',
              element: (
                <PermissionGuard moduleId="Setting">
                  <GoogleMapapi />
                </PermissionGuard>
              )
            },
            {
              id: 'sms',
              title: 'Sms',
              type: 'item',
              path: 'sms',
              element: <Sms />
            },
            {
              id: 'currency',
              title: 'Currency',
              type: 'item',
              path: 'currency',
              // element: < Currency />
              element: (
                <PermissionGuard moduleId="Setting">
                  <Currency />
                </PermissionGuard>
              )
            },
            {
              id: 'lcdwpaymentsettings',
              title: 'Lcdw Payment Settings',
              type: 'item',
              path: 'lcdwpaymentsettings',
              // element: < LcdwPaymentSettings />
              element: (
                <PermissionGuard moduleId="Setting">
                  <LcdwPaymentSettings />
                </PermissionGuard>
              )
            },
            {
              id: 'emailpreferences',
              title: 'Email Preferences',
              type: 'item',
              path: 'emailpreferences',
              // element: < EmailPreferences />
              element: (
                <PermissionGuard moduleId="Setting">
                  <EmailPreferences />
                </PermissionGuard>
              )
            },
            {
              id: 'fileformatsize',
              title: 'File Format Size',
              type: 'item',
              path: 'fileformatsize',
              // element: < FileFormat />
              element: (
                <PermissionGuard moduleId="Setting">
                  <FileFormat />
                </PermissionGuard>
              )
            },
            {
              id: 'location-limits',
              title: 'Location Limits',
              type: 'item',
              path: 'location-limits',
              // element: < Location/>
              element: (
                <PermissionGuard moduleId="Setting">
                  <Location />
                </PermissionGuard>
              )
            },
            {
              id: 'industry-list',
              title: 'Industry List',
              type: 'item',
              path: 'industry/list',
              // element: <IndustryList />
              element: (
                <PermissionGuard moduleId="Setting">
                  <IndustryList />
                </PermissionGuard>
              )
            },
            {
              id: 'country/list',
              title: 'Country List',
              type: 'item',
              path: 'country/list',
              // element: <CountryList />
              element: (
                <PermissionGuard moduleId="Setting">
                  <CountryList />
                </PermissionGuard>
              )
            },
            {
              id: 'email-template',
              title: 'Email Template',
              type: 'group',
              path: 'template',
              Breadcrumbs: true,
              children: [
                {
                  id: 'create-email',
                  title: 'Create Email',
                  type: 'item',
                  path: 'create-email',
                  // element: <MailTemplate />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <MailTemplate />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'header',
                  title: 'Header',
                  type: 'item',
                  path: 'header',
                  // element: <Header />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <Header />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'footer',
                  title: 'Footer',
                  type: 'item',
                  path: 'footer',
                  // element: <Footer />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <Footer />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'list-email',
                  title: 'Email Template List',
                  type: 'item',
                  path: 'list-email',
                  // element: <ListEmail />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <ListEmail />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'mailtemplate-view',
                  title: 'View',
                  type: 'item',
                  path: 'mailtemplate/view/:id',
                  // element: <ViewMailTemplate />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <ViewMailTemplate />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'template-edit',
                  title: 'Edit',
                  type: 'item',
                  path: 'template/edit/:id',
                  // element: < EditEmail />
                  element: (
                    <PermissionGuard moduleId="Setting">
                      <EditEmail />
                    </PermissionGuard>
                  )
                }
              ]
            }
          ]
        },
        // {
        //   path: 'default-location',
        //   element: < DefaultCountry/>
        // },
        // {
        //   path: 'email-template/activity',
        //   element: <Activity/>
        // },
        {
          id: 'admin/cms',
          title: 'Cms',
          type: 'group',
          path: 'admin/cms',
          Breadcrumbs: true,
          children: [
            {
              id: 'add',
              title: 'Add',
              type: 'item',
              path: 'add',
              // element: < CreateCms />
              element: (
                <PermissionGuard moduleId="Cms">
                  <CreateCms />
                </PermissionGuard>
              )
            },
            {
              id: 'list',
              title: 'List',
              type: 'item',
              path: 'list',
              // element: < ListCms />
              element: (
                <PermissionGuard moduleId="Cms">
                  <ListCms />
                </PermissionGuard>
              )
            },
            {
              id: 'edit',
              title: 'Edit',
              type: 'item',
              path: 'edit/:id',
              // element: < EditCms />
              element: (
                <PermissionGuard moduleId="Cms">
                  <EditCms />
                </PermissionGuard>
              )
            },
            {
              id: 'view',
              title: 'View',
              type: 'item',
              path: 'view/:id',
              // element: <ViewCms />
              element: (
                <PermissionGuard moduleId="Cms">
                  <ViewCms />
                </PermissionGuard>
              )
            }
          ]
        },
        // {
        //   path: 'admin/cms/preview',
        //   element: <PreviewPage/>
        // },
        {
          path: 'admin/sample-page3',
          element: <SamplePage3 />
        },
        {
          title: 'Company',
          path: 'admin/company',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'company-view',
              title: 'View Company',
              type: 'item',
              path: 'view',
              // element: <CompanyUserView />
              element: (
                <PermissionGuard moduleId="company_users">
                  <CompanyUserView />
                </PermissionGuard>
              )
            },
            {
              id: 'user-activity',
              title: 'View Sub-users',
              type: 'item',
              path: 'view-company-subuser/:id',
              // element: <CompanySubuser/>
    
              element: (
                <PermissionGuard moduleId="company_users">
                  <CompanySubuser />
                </PermissionGuard>
              )
            },
            {
              id: 'company-user-view',
              title: 'View User',
              type: 'item',
              path: 'users',
              // element: <CompanyUser />
              element: (
                <PermissionGuard moduleId="company_users">
                  <CompanyUser />
                </PermissionGuard>
              )
            },
          ]
        },
        // {
        //   path: 'admin/company-users/add',
        //   element: <CompanyUserAdd />
        // },
        {
          title: 'LCDW',
          path: 'admin/lcdw-user',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'lcdwusers',
              title: 'LCDW Users',
              type: 'item',
              path: 'view',
              // element: <LcdwUserView />
              element: (
                <PermissionGuard moduleId="lcdw_users">
                  <LcdwUserView />
                </PermissionGuard>
              )
            },
            {
              id: 'useractivity',
              title: 'View Users Activity',
              type: 'item',
              path: 'lcdw-users-activity',
              // element: <LcdwUserActivity />
              element: (
                <PermissionGuard moduleId="lcdw_users">
                  <LcdwUserActivity />
                </PermissionGuard>
              )
            },
            {
              id: 'companyuserview',
              title: 'View User Activity',
              type: 'item',
              path: 'view-lcdw-users-activity/:id',
              // element: <LcdwUserActivity />
              element: (
                <PermissionGuard moduleId="lcdw_users">
                  <LcdwViewActivity />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          title: 'Admin',
          path: 'admin/admin-users',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'permission',
              title: 'Permission',
              type: 'item',
              path: 'permission',
              // element: <Permission />
              element: (
                <PermissionGuard moduleId="admin_users">
                  <Permission />
                </PermissionGuard>
              )
            },
            {
              id: 'view-user',
              title: 'Admin Users',
              type: 'item',
              path: 'view-users', // Admin User
              // element: <ListOfSubUser />
              element: (
                <PermissionGuard moduleId="admin_users">
                  <ListOfSubUser />
                </PermissionGuard>
              )
            },
            // {
            //   path: 'admin-users/sub-user-mangement/add-sub-user',
            //   element: <AddSubUser />
            // },
            // {
            //   path: 'admin-users/sub-user-mangement/edit-sub-user',
            //   element: <EditSubUser />
            // },
            {
              id: 'user-activity',
              title: 'View User Activity',
              type: 'item',
              path: 'view-users-activity/:id',
              // element: <ViewSubUserActivity />
              element: (
                <PermissionGuard moduleId="admin_users">
                  <ViewSubUserActivity />
                </PermissionGuard>
              )
            },
            {
              id: 'user-activity',
              title: 'View Users Activity',
              type: 'item',
              path: 'view-user-activity',
              // element: <ViewActivity />
              element: (
                <PermissionGuard moduleId="admin_users">
                  <ViewActivity />
                </PermissionGuard>
              )
            },
            {
              title: 'Roles',
              path: 'roles',
              Breadcrumbs: true,
              type: 'group',
              children: [
                {
                  id: 'list-of-roles',
                  title: 'List Of Roles',
                  type: 'item',
                  path: 'list-of-roles',
                  // element: <ListOfRoles />
                  element: (
                    <PermissionGuard moduleId="admin_users">
                      <ListOfRoles />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'add-roles',
                  title: 'Add Roles',
                  type: 'item',
                  path: 'add-roles',
                  // element: <AddRoles />
                  element: (
                    <PermissionGuard moduleId="admin_users">
                      <AddRoles />
                    </PermissionGuard>
                  )
                },
                {
                  id: 'edit-role',
                  title: 'Edit Role',
                  type: 'item',
                  path: 'edit-role/:id',
                  // element: <EditRoles />
                  element: (
                    <PermissionGuard moduleId="admin_users">
                      <EditRoles />
                    </PermissionGuard>
                  )
                }
              ]
            },
          ]
        },
        {
          title: 'Staff',
          path: 'admin/staff',
          Breadcrumbs: true,
          type: 'group',
          children: [
            {
              id: 'view-staff-users',
              title: 'Staff Users',
              type: 'item',
              path: 'staff-users',
              // element: <ListOfAdminStaffUsers />
              element: (
                <PermissionGuard moduleId="staff_users">
                  <ListOfAdminStaffUsers/>
                </PermissionGuard>
              )
            },
            // {
            //   path: 'admin-users/staff-user-mangement/add-staff-user',
            //   element: <AddStaffUserActivity />
            // },
            {
              id: 'view-staff-users-activity',
              title: 'Users Activity',
              type: 'item',
              path: 'users-activity',
              // element: <ViewStaffUserActivity />
              element: (
                <PermissionGuard moduleId="staff_users">
                  <ViewStaffUserActivity />
                </PermissionGuard>
              )
            },
            {
              id: 'view-staff-follow-uplist',
              title: 'Follow Up List',
              type: 'item',
              path: 'follow-uplist',
              // element: <ViewStaffFollowUpList />
              element: (
                <PermissionGuard moduleId="staff_users">
                  <ViewStaffFollowUpList />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'admin-feedback',
          title: 'Feedback',
          type: 'group',
          path: 'admin/feedback',
          Breadcrumbs: true,
          children: [
            {
              id: 'contact',
              title: 'Contact',
              type: 'item',
              path: 'contact',
              // element: <FeedbackContact />
              element: (
                <PermissionGuard moduleId="FeedbackList">
                  <FeedbackContact />
                </PermissionGuard>
              )
            },
            {
              id: 'location',
              title: 'Location',
              type: 'item',
              path: 'location',
              // element: <LocationFeedback />
              element: (
                <PermissionGuard moduleId="FeedbackList">
                  <LocationFeedback />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'admin-logs',
          title: 'Logs',
          type: 'group',
          path: 'admin/logs',
          Breadcrumbs: true,
          children: [
            {
              id: 'location-logs',
              title: 'Location Logs',
              type: 'item',
              path: 'location-logs',
              // element: < LocationLogs />
              element: (
                <PermissionGuard moduleId="Logs">
                  <LocationLogs />
                </PermissionGuard>
              )
            },
            {
              id: 'payment-logs',
              title: 'Payment Logs',
              type: 'item',
              path: 'payment-logs',
              // element: (
              //   <PaymentLogs />
              // ),
              element: (
                <PermissionGuard moduleId="Logs">
                  <PaymentLogs />
                </PermissionGuard>
              )
            },
            {
              id: 'login-logs',
              title: 'Login Logs',
              type: 'item',
              path: 'login-logs',
              // element: <LoginLogs />
              element: (
                <PermissionGuard moduleId="Logs">
                  <LoginLogs />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          path: 'admin/check-list',
          element: <CartCheckList />
        },
        {
          id: 'view-invoice',
          title: 'View Invoice',
          type: 'item',
          path: 'admin/view-invoices/:id',
          // element: <ViewLocation  />
          element: (
            <PermissionGuard moduleId="Location">
              <Invoices />
            </PermissionGuard>
          )
        },
        {
          id: 'payment-statements',
          title: 'Payment Statements',
          type: 'group',
          path: 'admin/payment-statements',
          Breadcrumbs: true,
          children: [
            {
              id: 'statements',
              title: 'Statements',
              type: 'item',
              path: 'statements',
              // element: (
              //   <Statements />
              // )
              element: (
                <PermissionGuard moduleId="PaymentStatements">
                  <Statements />
                </PermissionGuard>
              )
            },
            {
              id: 'invoices',
              title: 'Invoices',
              type: 'item',
              path: 'invoices',
              element: <InvoiceReports />
            },
            {
              id: 'lcdw-withdrawal',
              title: 'LCDW Withdrawal Request',
              type: 'item',
              path: 'lcdw-withdrawal',
              // element: <LcdwWithdrawal />PaymentStatements
              element: (
                <PermissionGuard moduleId="PaymentStatements">
                  <LcdwWithdrawal />
                </PermissionGuard>
              )
            },
            {
              id: 'lcdw-payment-statement',
              title: 'LCDW Payment Statement',
              type: 'item',
              path: 'lcdw-payment-statement',
              element: (
                <PermissionGuard moduleId="PaymentStatements">
                  <LcdwPaymentStatement />
                </PermissionGuard>
              )
            }
          ]
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'group',
          path: 'admin/reports',
          Breadcrumbs: true,
          children: [
            {
              id: 'payment-reports',
              title: 'Payment Reports',
              type: 'item',
              path: 'payment-reports',
              // element: < />
              element: (
                <PermissionGuard moduleId="Reports">
                  <PaymentReports />
                </PermissionGuard>
              )
            },
            {
              id: 'Withdrawal-reports',
              title: 'Withdrawal Reports',
              type: 'item',
              path: 'withdrawal-reports',
              // element: <WithdrawalReports />
              element: (
                <PermissionGuard moduleId="Reports">
                  <WithdrawalReports />
                </PermissionGuard>
              )
            },
            {
              id: 'location-reports',
              title: 'Location Reports',
              type: 'item',
              path: 'location-reports',
              element: (
                <PermissionGuard moduleId="Reports">
                  <LocationReports />
                </PermissionGuard>
              )
            }
          ]
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: '404_forbidden',
          element: <MaintenanceErrorFor />
        }
      ]
    },

  ]
};

export default MainRoutes;
